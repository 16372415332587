@import "styles/mixins";

packex-carousel {
  display: flex;
  max-width: 100%;
  border-radius: .25rem;
  @include box-shadow();

  &[size=xl] {
    padding: 0;
  }
}

ngb-carousel {
  width: 100%;

  .wrapper {
    position: relative;
    height: 0;
    padding-top: 55%; /* Keep ratio for 900x500 images */
  }

  .carousel-control-prev, .carousel-control-next {
    width: 5%;
  }

  .carousel-indicators button[data-bs-target] {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 100%;
  }
}
